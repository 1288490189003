import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import "./ModelComponent.css"; // Import the CSS file
import * as THREE from "three"; // Import THREE here

/* Useful Links 

To view the model: 
1. https://gltf-viewer.donmccurdy.com/ - Animations can be seen. 
2. https://gltf.pmnd.rs/
 
*/

function Model(props) {
  const group = useRef();

  /* 
      MODEL LOADING
  */
  const { scene, animations } = useGLTF(
    "https://website-storage-assets45457-staging.s3.us-west-2.amazonaws.com/danny_model/Danny_Rig_Blink_Working.gltf"
  );
  console.log("Animation", animations);
  animations[0].tracks.forEach((track) => console.log(track, track.name));

  /* 
      ANIMATION - The idea is load basic animations from model and 
                  lazy load complex animations later. Understand how the 
                  animation works is a crutial point in making the model live.
      
      Idea - Can run a neural network to decide the next animation (for real-time animation). 
             So should be able to load animation on the go from cloud and render in the coming 
             frames - TODO Investiage!! 💡
  */
  // Create a mixer to handle the animations
  const mixerRef = useRef(null);

  useEffect(() => {
    mixerRef.current = new THREE.AnimationMixer(group.current);
    // console.log("Current ", group.current);
    animations.forEach((clip) => {
      clip.tracks.forEach((track) => {
        if (track.name.startsWith("root.position")) {
          // Update track name to match the actual structure of your model
          // TODO Replace 'correctNodeName' with the actual name of the node in your model
          // TODO Also don't know how the animation is working, it supposedly shouldnt work. But something in this code is making it work.

          track.name = track.name.replace("root", "correctNodeName");
        }
      });

      mixerRef.current.clipAction(clip).play();
    });
    return () => mixerRef.current && mixerRef.current.stopAllAction();
  }, [animations]);

  useFrame((state, delta) => {
    mixerRef.current && mixerRef.current.update(delta);
  });

  /* 
      TEXTURES
  */
  // TODO: Load the texture file
  //   const textureFilenames = [
  //     // Add all the filenames here
  //     "01g.png",
  //     "dann_skin_spec.png",
  //   ];

  //   const textures = textureFilenames.map((filename) =>
  //   useLoader(THREE.TextureLoader, `danny_model/textures/${filename}`)
  // );

  // Apply the texture to the 3D model
  scene.traverse((child) => {
    console.log("Child:", child.name);
    if (child.isMesh) {
      // Map Mesh with Textures?
      console.log("Mesh:", child.name);
    }
    if (child instanceof THREE.AnimationMixer) {
      console.log("Animation Mixer:", child.name);
      console.log(
        "Animations:",
        child._actions.map((action) => action._clip.name).join(", ")
      );
    }
    if (child instanceof THREE.Camera) {
      console.log("Camera:", child.name);
      console.log("Position:", child.position.toArray().toString());
    }

    // if (child.isMesh && textures[index]) {
    //   child.material.map = textures[index];
    // }
  });

  // return <primitive object={scene} />;
  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={scene} />
    </group>
  );
}

export default function ModelComponent() {
  return (
    <div className="model-container">
      {/* Apply the CSS class here */}
      <Canvas pixelRatio={[1, 2]} camera={{ position: [-10, 15, 15], fov: 25 }}>
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
          <Model />
        </Suspense>
        <OrbitControls />
      </Canvas>
    </div>
  );
}
