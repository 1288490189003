
import BackgroundVideo from "../ui-components/BackgroundVideo.js";
import "./Layout.css";
import { useImperativeHandle } from "react";

import { HomeSecondRow } from "../ui-components/HomeSecondRow";
import HomeRowCart from "../ui-components/HomeRowCart";
function Layout(props) {
  const { ref } = props;
  useImperativeHandle(ref, () => ({
    focus: () => {
      // do something with the ref here
      ref.overlayElement.style.display = "none";
    },
  }));


  return (
    <div>
        <BackgroundVideo />
          <div className="row m-0 " style={{gap:"0px"}}>
          <HomeSecondRow sceneUrl="https://prod.spline.design/ZFEZDIWhUiNKG6Ar/scene.splinecode" />
            <HomeRowCart/>
          </div>
            

    </div>
  );
}

export default Layout;
