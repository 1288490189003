import { API } from "aws-amplify";
import { listClothingProducts } from "../graphql/queries";

const fetchProductsFromDDB = async () => {
  try {
    const productData = await API.graphql({
      query: listClothingProducts,
      variables: {
        filter: {
          IsVisible: { eq: true },
        },
      },
    });
    const productList = productData.data.listClothingProducts.items;
    return productList;
  } catch (error) {
    console.log("Error occurred while fetching products from database", error);
  }
};

export default fetchProductsFromDDB;
