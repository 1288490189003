import React,{ useEffect  } from 'react'
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useDispatch } from 'react-redux';
import { setAuthenticated, setUnauthenticated } from "../redux/auth";
const Login = () => {
    

    const dispatch = useDispatch();

    useEffect(() => {
        checkAuth();
      }, );
      
      async function checkAuth() {
        try {
          dispatch(setAuthenticated());
          window.location.href = window.location.origin + '/shop'
        } catch (error) {
          dispatch(setUnauthenticated());
          console.log("User is not authenticated");
        }
      }
  return (
    <div className='nullBack'>Login</div>
  )
}


export default withAuthenticator(Login)