import React from "react";
import "./ScrollText.css";

function ScrollText() {
  return (
    <div className="scroll-text">
      <h2>
        SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE!
        SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE! SALE!
      </h2>
    </div>
  );
}

export default ScrollText;
