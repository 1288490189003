import React from "react";

import Promotion from "./Promotion";
import FooterNew from "./FooterNew";
import "./Footer.css";
function Footer() {
  return (
    <div className="Footer">
      <div className="Promotions">
        <Promotion/>
        <FooterNew/>
      </div>
    </div>
  );
}

export default Footer;
