import React from "react";
// import GameComponent from "./GameComponent";

import GameComponent from "./GameComponent";
const GamePage = () => {
    return (
      <div>
        <h1>Game Page</h1>
        <GameComponent />
      </div>
    );
  };
  
  export default GamePage;
  