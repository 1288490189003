import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {addToCart} from "../redux/cartSlice"
import {  removeFromFav } from "../redux/favoritesSlice";
import "./FavCart.css"
const FavCart = ({ clothingProducts }) => {
  const [addtocartChildren, setAddtocartChildren] = useState("Add to cart");
  const [isFavorited, setIsFavorited] = useState(true);
  const dispatch = useDispatch();

  const addtocartOnClick = () => {
      dispatch(addToCart(clothingProducts));
    setAddtocartChildren("Added");
  };


  const removeFromFavorites = () => {
    setIsFavorited(true);
    dispatch(removeFromFav(clothingProducts.id));
  };
  return (
    <div className="product-tile">
      <div className="product-image">
        <img
          src={clothingProducts?.Image}
          alt={clothingProducts?.Name}
          className="image"
        />
      </div>
      <div className="product-details">
        <h3 className="product-name">{clothingProducts?.Name}</h3>
        <p className="product-price">${clothingProducts?.Price}</p>
      </div>
      <div className="color-choices">
        <div className="color-choice1"></div>
        <div className="color-choice2"></div>
        <div className="color-choice3"></div>
        <div className="color-choice4"></div>
      </div>
      <div className="actions">
        <button className="add-to-cart-button" onClick={addtocartOnClick}>
          {addtocartChildren}
        </button>
        {isFavorited && (
          <button className="favorite-button1" onClick={removeFromFavorites}>
           <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="1em" viewBox="0 0 512 512"><path d="M473.7 73.8l-2.4-2.5c-46-47-118-51.7-169.6-14.8L336 159.9l-96 64 48 128-144-144 96-64-28.6-86.5C159.7 19.6 87 24 40.7 71.4l-2.4 2.4C-10.4 123.6-12.5 202.9 31 256l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0L481 255.9c43.5-53 41.4-132.3-7.3-182.1z"/></svg>


            
          </button>
        )}
      </div>
    </div>
  );
};
FavCart.propTypes = {
  clothingProducts: PropTypes.shape({
    id: PropTypes.number,
    Name: PropTypes.string,
    Price: PropTypes.number,
    Image: PropTypes.string,
  }),
};

export default FavCart;
