import React from "react";
import "./PageNotFoundPage.css";
import PageNotFound from "../ui-components/PageNotFound";

function PageNotFoundPage() {
  return (
    <div class="PageNotFoundPage">
      <PageNotFound></PageNotFound>
    </div>
  );
}

export default PageNotFoundPage;
