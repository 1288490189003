import "./AccountPage.css";
import React from "react";
// import PopupContainer from "./PopupContainer";
import { Container } from './Container';
function AccountPage() {
  const triggerText = 'Edit';
   const onSubmit = (event) => {
   event.preventDefault(event);
 };
  return (
    <div className="accounts">
      <div className="removeFooterMargin row">
        <div className="col-sm-12 col-12 mainLabelCol">
          <h1 className="mainLabel">Account Settings</h1>
        </div>
      </div>
      <div className="removeFooterMargin row labelRow">
        <div className="col-md-2 col-sm-2 col-3 labelsCol">
            <h3 className="labels">Shipping</h3>
        </div>
        <div className="col-sm-3 col-3">
          <h4 className="subLabel">Shipping Address</h4>
          <Container triggerText={triggerText} onSubmit={onSubmit} /> 
        </div>
        <div className="col-sm-3 col-3">
          <h4 className="subLabel">Contact Information</h4>
          <Container triggerText={triggerText} onSubmit={onSubmit} /> 
        </div>
      </div>
      <div className="removeFooterMargin row labelRow">
        <div className="col-md-2 col-cm-2 col-3 labelsCol">
            <h3 className="labels">Payment</h3>
        </div>
        <div className="col-sm-3 col-3">
          <h4 className="subLabel">Billing Contact</h4>
          <Container triggerText="Edit | Remove" onSubmit={onSubmit} /> 
        </div>
        <div className="col-sm-3 col-3">
          <h4 className="subLabel">Billing Address</h4>
          <Container triggerText="Edit | Remove"  onSubmit={onSubmit} /> 
        </div>
        <div className="col-sm-3 col-3">
          <h4 className="subLabel">Payment Method</h4>
          <Container triggerText="Edit | Remove"  onSubmit={onSubmit} /> 
        </div>
      </div>
      <div className="removeFooterMargin row labelRow">
        <div className="col-md-2 col-sm-2 col-3 labelsCol">
            <h3 className="labels">Privacy</h3>
        </div>
        <div className="col-sm-9 col-9">
          <h4 className="subLabel">Personal Information</h4>
          <span className="content">
          You're in control of your personal information and can manage your data or delete your account
          at any time. Apple is committed to protecting your privacy.
          </span>
          <p className="editBtn">Manage my personal information &gt;</p>
          {/* <Container triggerText={triggerText} onSubmit={onSubmit} />  */}
        </div>
      </div>
    </div>
  );
}

export default AccountPage;
