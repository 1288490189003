import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MessageIcon } from "./assets/SVG/message.svg";
import { ReactComponent as CloseIcon } from "./assets/SVG/close.svg";
import { ReactComponent as LockIcon } from "./assets/SVG/lock.svg";
import { selectAuthenticated } from "./redux/auth";
import MicrophoneIcon from "./assets/microphone.png";
import PlayIcon from "./assets/play-button.png";
import PauseIcon from "./assets/pause.png";
import { useSelector } from "react-redux";

import axios from "axios";
import "./AddMessage.css";

const AddMessage = () => {
  const authenticated = useSelector(selectAuthenticated);
  const messagesContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [newAudioMessage, setNewAudioMessage] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioElement, setAudioElement] = useState(null);
  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem("chatMessages"));
    if (storedMessages) {
      setMessages(storedMessages);
    }
  }, []);

  const toggleMessage = () => {
    setIsOpen(!isOpen);
  };

  const addMessage = async () => {
    if (newMessage) {
      const updatedMessages = [...messages, { text: newMessage, isUser: true }];
      const messagesSlice = updatedMessages.slice(-9);

      const messagesWithRoles = messagesSlice.map((message, index) => ({
        n: index + 1,
        content: message.text,
        role: message.isUser ? "user" : "assistant",
      }));
      const current_conversation = messagesWithRoles.pop();
      const pastConversation = {
        N: messagesWithRoles.length,
        past_conversation: messagesWithRoles,
        current_conversation: current_conversation,
      };
      console.log(pastConversation);
      setMessages(updatedMessages);
      localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
      setNewMessage("");
      setNewAudioMessage(null);
      // console.log(localStorage.getItem("chatMessages"));

      // Call the AWS API endpoint using Axios
      const endpointURL = "https://api.bharatsesham.com/generate";

      try {
        const response = await axios.post(endpointURL, {
          input: pastConversation,
        });
        const desiredResult = response.data;

        if (desiredResult.generated_image_url) {
          const imageMessage = {
            imageSrc: desiredResult.generated_image_url,
            isUser: false,
          };
          const updatedMessagesWithImage = [...updatedMessages, imageMessage];
          setMessages(updatedMessagesWithImage);
          localStorage.setItem(
            "chatMessages",
            JSON.stringify(updatedMessagesWithImage)
          );
        } else if (
          desiredResult.speech &&
          desiredResult.speech.generated_speech
        ) {
          const audioMessage = {
            audioSrc: desiredResult.speech.generated_speech,
            isUser: false,
          };
          if (audioElement) {
            audioElement.pause();
          }
          const newAudioElement = new Audio(audioMessage.audioSrc);
          newAudioElement.play();
          setAudioElement(newAudioElement);
          setIsPlaying(true);

          setNewAudioMessage(audioMessage);
          newAudioElement.addEventListener("ended", () => {
            setIsPlaying(false);
          });
          // TODO: Make this efficient - also audio change is messing up the conversation change from above and also itroducing logout bug - call to logout is failing and not logging out.
          const textMessage = {
            text: desiredResult.text.generated_text,
            isUser: false,
          };
          const updatedMessagesWithText = [...updatedMessages, textMessage];
          setMessages(updatedMessagesWithText);
          localStorage.setItem(
            "chatMessages",
            JSON.stringify(updatedMessagesWithText)
          );
        } else if (desiredResult.text && desiredResult.text.generated_text) {
          const textMessage = {
            text: desiredResult.text.generated_text,
            isUser: false,
          };
          const updatedMessagesWithText = [...updatedMessages, textMessage];
          setMessages(updatedMessagesWithText);
          localStorage.setItem(
            "chatMessages",
            JSON.stringify(updatedMessagesWithText)
          );
        }
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }
        setIsSend(false);
        // var objDiv = document.getElementById("conversationBox");
        // objDiv.scrollTop = objDiv.scrollHeight;
        console.log(desiredResult);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsSend(true);
  //   addMessage();
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSend(true);
    setIsLoading(true);
    await addMessage();
    setIsLoading(false);
    setIsSend(false);
  };

  // Recording
  const [isRecording, setIsRecording] = useState(false);
  const [capturedText, setCapturedText] = useState("");
  useEffect(() => {
    let recognition;

    if (isRecording) {
      recognition =
        new window.webkitSpeechRecognition() || new window.SpeechRecognition();

      recognition.lang = "en-US";
      recognition.continuous = true; // Continue recognition until explicitly stopped

      recognition.onresult = (event) => {
        const capturedText =
          event.results[event.results.length - 1][0].transcript;
        console.log("Speech captured:", capturedText);
        setIsRecording(false);
        setCapturedText(capturedText);
        setNewMessage(capturedText);
        addMessage();
      };

      recognition.onend = () => {
        setIsRecording(false); // Ensure recognition stops after the timeout even if no speech is captured
      };

      recognition.onaudioend = () => {
        setTimeout(() => {
          recognition.stop();
        }, 2000);
      };

      recognition.start();
    }

    // Clean up on unmount and stop recognition
    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, [isRecording]);
  const handleRecordButtonClick = () => {
    setIsRecording(true);
    setCapturedText("");
    setNewMessage("");
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    if (isOpen && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [isOpen]);

  const handlePlayAudio = () => {
    if (audioElement) {
      if (audioElement.paused) {
        audioElement.play();
        setIsPlaying(true);
      } else {
        audioElement.pause();
        setIsPlaying(false);
      }
    } else if (newAudioMessage) {
      const newAudioElement = new Audio(newAudioMessage.audioSrc);
      newAudioElement.play();
      setAudioElement(newAudioElement);
      setIsPlaying(true);

      newAudioElement.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }
  };

  return (
    <div className="messageBox">
      {authenticated && !isOpen ? (
        <div className="messageIconContainer" onClick={toggleMessage}>
          <MessageIcon className="messageIcon" width="25px" height="22px" />
        </div>
      ) : authenticated && isOpen ? (
        <div className="messageIconContainer1" onClick={toggleMessage}>
          <CloseIcon className="messageIcon" width="25px" height="22px" />
        </div>
      ) : null}
      {isOpen ? (
        <div className="messagePopup">
          <div className="popupHeader">
            <div className="popupTitle">Chat</div>
            <button className="closeButton" onClick={toggleMessage}>
              <CloseIcon width="18px" height="16px" />
            </button>
          </div>
          <div
            className="msgContent content"
            id="conversationBox"
            ref={messagesContainerRef}
          >
            <div className="conversation">
              {messages.map((message, index) => (
                <div
                  className={`message ${message.isUser ? "userMessage" : ""}`}
                  key={index}
                >
                  {message.text && <p>{message.text}</p>}
                  {message.imageSrc && (
                    <img
                      className="responseImage"
                      src={message.imageSrc}
                      alt="receivedResponse"
                    />
                  )}
                  {message.audioSrc && (
                    <audio
                      className="responseAudio"
                      src={message.audioSrc}
                      controls
                    />
                  )}
                  {index === messages.length - 1 && newAudioMessage && (
                    <img
                      src={isPlaying ? PauseIcon : PlayIcon}
                      alt={isPlaying ? "pause" : "play"}
                      className="playButton"
                      type="button"
                      onClick={handlePlayAudio}
                    />
                  )}
                </div>
              ))}
            </div>
            <form onSubmit={handleSubmit} className="msgFormSubmit">
              {/* Input box to add a new message */}
              {/* <button className="recordButton" type="button" onClick={handleRecordButtonClick}>
                Record
              </button> */}
              <img
                src={MicrophoneIcon}
                alt="Microphone Icon"
                className="recordButton"
                type="button"
                onClick={handleRecordButtonClick}
              />
              <input
                type="text"
                value={newMessage}
                className="msgInput"
                onChange={handleInputChange}
                placeholder="Enter your message"
              />
              <button
                disabled={isSend || isLoading}
                className="msgSendBtn"
                type="submit"
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            </form>
          </div>
        </div>
      ) : (
        !authenticated && (
          <div className="lockedIconContainer">
            <LockIcon className="messageIcon" width="25px" height="22px" />
          </div>
        )
      )}
    </div>
  );
};

export default AddMessage;
