import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as OpenIcon } from "./assets/SVG/open.svg";
import { ReactComponent as CloseIcon } from "./assets/SVG/close.svg";
import "./VideoMessage.css";

const videos = [
  {
    title: "Alfred",
    url: "https://website-storage-assets45457-staging.s3.us-west-2.amazonaws.com/AI+Videos/Male+Video1.mp4",
  },
  {
    title: "Eva",
    url: "https://website-storage-assets45457-staging.s3.us-west-2.amazonaws.com/AI+Videos/Female+video1.mp4",
  },
];

const VideoMessage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);

  const toggleMessage = () => {
    setIsOpen(!isOpen);
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  const handleVideoClick = () => {
    togglePlay();
  };

  useEffect(() => {
    if (isOpen) {
      const randomIndex = Math.floor(Math.random() * videos.length);
      setCurrentVideoIndex(randomIndex);
    }
  }, [isOpen]);

  return (
    <div className="videoMessageBox">
      <div className="videoIconContainer" onClick={toggleMessage}>
        {isOpen ? (
          <div className="videoHeader">
            <p className="videoTitle">{videos[currentVideoIndex].title}</p>
            <CloseIcon className="icon closeIcon" width="24px" height="24px" />
          </div>
        ) : (
          <OpenIcon className="icon" width="45px" height="42px" />
        )}
      </div>
      {isOpen && (
        <div className="messagePopup">
          <div className="msgContent content">
            {/* Video Player */}
            <div className="videoPlayer">
              {!videos[currentVideoIndex] ? (
                <div className="videoLoading">
                  <div className="loadingSpinner"></div>
                </div>
              ) : (
                <div className="videoContainer">
                  <video
                    key={videos[currentVideoIndex].url}
                    ref={videoRef}
                    src={videos[currentVideoIndex].url}
                    onClick={handleVideoClick}
                    autoPlay
                  ></video>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoMessage;
