import React from "react";
import "./CollectionPage.css";
import { SampleCollectionsPage } from "../ui-components";

function CollectionPage() {
  return (
    <div className="CollectionPage">
      <h1>Collection Page</h1>
      <SampleCollectionsPage></SampleCollectionsPage>
    </div>
  );
}

export default CollectionPage;
