import "./VirtualPet.css";
import virtualPet from "../assets/extras/pikachu.gif";
import React, { useEffect, useRef } from "react";

function VirtualPet() {
  const directionRef = useRef(1); // 1 for right, -1 for left

  useEffect(() => {
    const animate = () => {
      const pet = document.getElementById("pet");

      const speed = 0.5; // Speed of the pet in pixels per frame

      const rect = pet.getBoundingClientRect();
      const x = rect.left;
      const width = window.innerWidth-16;

      // If the pet hits the edge of the screen, turn around and invert image
      if (x > width - pet.offsetWidth && directionRef.current === 1) {
        directionRef.current = -1;
        pet.classList.add("invert");
      } else if (x <= 0 && directionRef.current === -1) {
        directionRef.current = 1;
        pet.classList.remove("invert");
      }

      // Move the pet
      pet.style.left = x + speed * directionRef.current + "px";

      // Call animate again on the next frame
      requestAnimationFrame(animate);
    };

    animate();
  }, []); // Removed direction from dependency array

  return (
    <div className="pet">
      <img
        id="pet"
        src={virtualPet}
        alt="Pet"
        style={{ position: "absolute", width: "50px", left: "0px" }}
      />
    </div>
  );
}

export default VirtualPet;
