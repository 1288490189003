import React from 'react';
import "./btn.css"
const Trigger = ({ triggerText, buttonRef, showModal }) => {
  return (
    <span
      className="center modal-button editBtn"
      ref={buttonRef}
      onClick={showModal}
    >
      {triggerText}
    </span>
  );
};
export default Trigger;
