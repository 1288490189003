import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"
import { addToFav, removeFromFav } from "../redux/favoritesSlice";
import "./ProductItems.css";
import { ReactComponent as LocationIcon } from "../assets/SVG/location.svg";
import {  Dialog, DialogContent } from "@mui/material";
const ProductItems = ({ clothingProducts }) => {

  const options = [
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
  ];
  //popup flag
  const [open, setOpen] = useState(false);
  const [addtocartChildren, setAddtocartChildren] = useState("Add to cart");
  const [isFavorited, setIsFavorited] = useState(false);
  const dispatch = useDispatch();


  const addtocartOnClick = () => {
    dispatch(addToCart(clothingProducts));
    setAddtocartChildren("Added");
  };

  const addToFavorites = () => {
    setIsFavorited(true);
    dispatch(addToFav(clothingProducts));
  };

  const removeFromFavorites = () => {
    setIsFavorited(false);
    dispatch(removeFromFav(clothingProducts.id));
  };
  //popup controllers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="product-tile">
      <div className="product-image">
        <img
          onClick={handleOpen}
          src={clothingProducts?.Image}
          alt={clothingProducts?.Name}
          className="image"
        />
      </div>
      <div className="product-details">
        <h3 className="product-name" onClick={handleOpen}>{clothingProducts?.Name}</h3>
        <p className="product-price" onClick={handleOpen}>${clothingProducts?.Price}</p>
      </div>
      <div className="color-choices">
        <div className="color-choice1"></div>
        <div className="color-choice2"></div>
        <div className="color-choice3"></div>
        <div className="color-choice4"></div>
      </div>
      <div className="actions">
        <button className="add-to-cart-button" onClick={addtocartOnClick}>
          {addtocartChildren}
        </button>
        {!isFavorited && (
          <button className="favorite-button1" onClick={addToFavorites}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path
                d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                fill={isFavorited ? "red" : "white"}
              />
            </svg>
          </button>
        )}
        {isFavorited && (
          <button className="favorite-button1" onClick={removeFromFavorites}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path
                d="M473.7 73.8l-2.4-2.5c-46-47-118-51.7-169.6-14.8L336 159.9l-96 64 48 128-144-144 96-64-28.6-86.5C159.7 19.6 87 24 40.7 71.4l-2.4 2.4C-10.4 123.6-12.5 202.9 31 256l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0L481 255.9c43.5-53 41.4-132.3-7.3-182.1z"
                fill={isFavorited ? "red" : "white"}
              />
            </svg>



          </button>
        )}
      </div>
      {/* Popup Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{
          style: {
            width: "74%",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          },
        }}>
        <DialogContent>

          <div className="removeMargin row">
            <div className="removeMargin row col-8">
              <div className="col-12"
                style={{ marginBottom: "4px" }}>
                <img
                  src={clothingProducts?.Image}
                  alt={clothingProducts?.Name}
                  className="popup-image-item"
                  style={{ width: "100%", height: "fit-content" }}
                />
              </div>
              {[...Array(5)].map((_, index) => (
                <div className="col-6" key={index}
                  style={{ marginTop: "4px" }}>
                  <img
                    src={clothingProducts?.Image}
                    alt={clothingProducts?.Name}
                    className="popup-image-item"
                    style={{ width: "100%" }}
                  />
                </div>
              ))}
            </div>
            <div className="col-4 p-0">
              <div className="removeMargin row">
                <div className="col-10">
                  <span className="popupTitle">{clothingProducts?.Name}</span><br />
                  <span className="popUpDescription">{clothingProducts?.Description}</span>
                </div>
                <div className="col-2">
                  {!isFavorited && (
                    <button className="favorite-button1" onClick={addToFavorites}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                        <path
                          d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                          fill={isFavorited ? "red" : "white"}
                        />
                      </svg>
                    </button>
                  )}
                  {isFavorited && (
                    <button className="favorite-button1" onClick={removeFromFavorites}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                        <path
                          d="M473.7 73.8l-2.4-2.5c-46-47-118-51.7-169.6-14.8L336 159.9l-96 64 48 128-144-144 96-64-28.6-86.5C159.7 19.6 87 24 40.7 71.4l-2.4 2.4C-10.4 123.6-12.5 202.9 31 256l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0L481 255.9c43.5-53 41.4-132.3-7.3-182.1z"
                          fill={isFavorited ? "red" : "white"}
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
              <div className="removeMargin row">
                {/* <input className="popUpInputSize" placeholder="Select Your Size"></input> */}
                <select className="popUpInputSize">
                  {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
                <button className="btn btn-light popUpSizeButton">Find Your Size</button>

              </div>
              <hr className="popUpPriceLine" />
              <div className="removeMargin row pricePopupRow">
                <span className="popUpPrice">{clothingProducts?.Price}</span>
              </div>
              <div className="removeMargin row">
                <button className="popUpAddButton" onClick={addtocartOnClick}>
                  {addtocartChildren}
                </button>
              </div>
              <div className="removeMargin row">
                <span className="popupShippingContent">By placing your order you agree to the terms of service. Please note, shipping
                  to US Territories is not available.</span>
              </div>
              <div className="removeMargin row popupDescriptionBox">
                <ExpandableElement title="Description" content="Content for Element 1" />
                <ExpandableElement title="Size & Fit" content="Content for Element 2" />
                <ExpandableElement title="Delivery & returns" content="Content for Element 3" />
              </div>
              <div className="removeMargin row popupOtherVersion">
                <div className="col-12"><span className="popupSimilar">Other versions available</span></div>
                {[...Array(4)].map((_, index) => (
                  <div className="col-6" key={index}
                    style={{ marginTop: "4px" }}>
                    <img
                      src={clothingProducts?.Image}
                      alt={clothingProducts?.Name}
                      className="popup-image-item"
                      style={{ width: "100%" }}
                    />
                  </div>
                ))}
              </div>
              <div className="removeMargin row popupBottomInstructions">
                <div className="col-12 popupInstructions">
                  <div className="row removeMargin firstElementpopBottom">
                    <div className="col-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M15.67 14.4801L10.5 9.36007C11.296 8.39515 11.7393 7.18774 11.7568 5.937C11.7743 4.68626 11.3649 3.46692 10.5962 2.48013C9.82749 1.49335 8.74537 0.798068 7.52837 0.508996C6.31137 0.219923 5.03219 0.354327 3.90184 0.890034C2.7715 1.42574 1.85753 2.33075 1.31071 3.45576C0.763886 4.58076 0.616882 5.85856 0.893946 7.07835C1.17101 8.29815 1.85559 9.38706 2.83475 10.1655C3.81391 10.9438 5.02916 11.3652 6.28001 11.3601C7.56363 11.3665 8.80773 10.9164 9.79001 10.0901L14.93 15.2201C15.0247 15.3094 15.1499 15.3591 15.28 15.3591C15.4101 15.3591 15.5354 15.3094 15.63 15.2201C15.6842 15.1761 15.7286 15.1212 15.7603 15.059C15.792 14.9968 15.8103 14.9286 15.8141 14.8589C15.8178 14.7892 15.807 14.7195 15.7821 14.6542C15.7573 14.589 15.7191 14.5297 15.67 14.4801ZM6.32001 10.3301C5.72906 10.3301 5.1439 10.2137 4.59793 9.98752C4.05197 9.76138 3.55589 9.42991 3.13803 9.01205C2.72017 8.59418 2.3887 8.09811 2.16255 7.55214C1.93641 7.00618 1.82001 6.42101 1.82001 5.83007C1.82001 5.23912 1.93641 4.65396 2.16255 4.10799C2.3887 3.56203 2.72017 3.06595 3.13803 2.64809C3.55589 2.23022 4.05197 1.89875 4.59793 1.67261C5.1439 1.44646 5.72906 1.33007 6.32001 1.33007C7.51348 1.33007 8.65808 1.80417 9.50199 2.64809C10.3459 3.492 10.82 4.63659 10.82 5.83007C10.82 7.02354 10.3459 8.16813 9.50199 9.01205C8.65808 9.85596 7.51348 10.3301 6.32001 10.3301Z" fill="black" />
                      </svg>
                    </div>
                    <div className="col-8">
                      <span className="borderInfoOne infoOneBorder">Availability in boutiques</span>
                    </div>
                  </div>
                  <div className="row removeMargin">
                    <div className="col-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                        <path d="M9.99983 15.28C8.36983 15.28 6.26983 14.45 3.65983 11.84C-1.42017 6.75997 0.229828 3.59997 1.65983 2.15997C2.2725 1.52332 3.08367 1.11421 3.95983 0.999973C4.02727 0.993036 4.09541 1.00109 4.15938 1.02357C4.22334 1.04604 4.28155 1.08238 4.32983 1.12997L6.32983 3.12997C6.4086 3.20707 6.45776 3.30941 6.46873 3.41908C6.4797 3.52876 6.45177 3.6388 6.38983 3.72997L4.60983 6.43997L9.05983 10.89L11.7298 9.09997C11.8216 9.03989 11.9313 9.01308 12.0404 9.024C12.1496 9.03492 12.2517 9.0829 12.3298 9.15997L14.3298 11.16C14.3789 11.2097 14.4168 11.2693 14.4409 11.3348C14.4651 11.4004 14.4749 11.4703 14.4698 11.54C14.3572 12.4124 13.9559 13.2221 13.3298 13.84C12.8986 14.2896 12.3819 14.6485 11.81 14.8958C11.2382 15.1431 10.6228 15.2737 9.99983 15.28ZM3.82983 1.99997C3.26214 2.13115 2.74252 2.41867 2.32983 2.82997C-0.280172 5.43997 1.97983 8.82997 4.32983 11.15C6.67983 13.47 10.0498 15.77 12.6598 13.15C13.0711 12.7373 13.3586 12.2177 13.4898 11.65L11.9398 10.1L9.25983 11.9C9.16802 11.9601 9.0584 11.9869 8.94923 11.9759C8.84006 11.965 8.73792 11.917 8.65983 11.84L3.65983 6.83997C3.58276 6.76188 3.53477 6.65974 3.52385 6.55057C3.51294 6.4414 3.53975 6.33178 3.59983 6.23997L5.38983 3.55997L3.82983 1.99997Z" fill="black" />
                      </svg>
                    </div>
                    <div className="col-8">
                      <span className="borderInfoOne">Contact us</span>
                    </div>
                  </div>
                  <div className="row removeMargin">
                    <div className="col-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M17 5H14.5V3.5C14.5 3.36739 14.4473 3.24021 14.3536 3.14645C14.2598 3.05268 14.1326 3 14 3C13.8674 3 13.7402 3.05268 13.6464 3.14645C13.5527 3.24021 13.5 3.36739 13.5 3.5V5H7.5V3.5C7.5 3.36739 7.44732 3.24021 7.35355 3.14645C7.25979 3.05268 7.13261 3 7 3C6.86739 3 6.74021 3.05268 6.64645 3.14645C6.55268 3.24021 6.5 3.36739 6.5 3.5V5H4C3.86739 5 3.74021 5.05268 3.64645 5.14645C3.55268 5.24021 3.5 5.36739 3.5 5.5V16.5C3.5 16.6326 3.55268 16.7598 3.64645 16.8536C3.74021 16.9473 3.86739 17 4 17H17C17.1326 17 17.2598 16.9473 17.3536 16.8536C17.4473 16.7598 17.5 16.6326 17.5 16.5V5.5C17.5 5.36739 17.4473 5.24021 17.3536 5.14645C17.2598 5.05268 17.1326 5 17 5ZM16.55 16.05H4.5V6H16.6L16.55 16.05Z" fill="black" />
                        <path d="M14 9H7C6.86739 9 6.74021 9.05268 6.64645 9.14645C6.55268 9.24021 6.5 9.36739 6.5 9.5C6.5 9.63261 6.55268 9.75979 6.64645 9.85355C6.74021 9.94732 6.86739 10 7 10H14C14.1326 10 14.2598 9.94732 14.3536 9.85355C14.4473 9.75979 14.5 9.63261 14.5 9.5C14.5 9.36739 14.4473 9.24021 14.3536 9.14645C14.2598 9.05268 14.1326 9 14 9ZM14 12H7C6.86739 12 6.74021 12.0527 6.64645 12.1464C6.55268 12.2402 6.5 12.3674 6.5 12.5C6.5 12.6326 6.55268 12.7598 6.64645 12.8536C6.74021 12.9473 6.86739 13 7 13H14C14.1326 13 14.2598 12.9473 14.3536 12.8536C14.4473 12.7598 14.5 12.6326 14.5 12.5C14.5 12.3674 14.4473 12.2402 14.3536 12.1464C14.2598 12.0527 14.1326 12 14 12Z" fill="black" />
                      </svg>
                    </div>
                    <div className="col-8">
                      <span className="borderInfoOne">Book your appointment in boutique</span>
                    </div>
                  </div>
                  <div className="row removeMargin lastElementpopBottom">
                    <div className="col-1">
                      <LocationIcon className="icon locationIcon" width="24px" height="24px" />
              
                    </div>
                    <div className="col-8">
                      <span className="borderInfoOne">Find a boutique</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
};
ProductItems.propTypes = {
  clothingProducts: PropTypes.shape({
    id: PropTypes.number,
    Name: PropTypes.string,
    Price: PropTypes.number,
    Image: PropTypes.string,
  }),
};

export default ProductItems;

const ExpandableElement = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="col-12 desctiptionEachEle">
      <div className={`arrow ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand} >
        <span className="descriptionTitle">{title}</span>
        <span className="arrowSpan"></span>
      </div>
      {isExpanded && <div>{content}</div>}
    </div>
  );
};
