import "./CheckoutPage.css";
import React from "react";

function CheckoutPage() {
  return (
    <div className="checkout">
      <h1>In Build</h1>
    </div>
  );
}

export default CheckoutPage;
