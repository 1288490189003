export const SHOW_HEADER = true;
export const SHOW_FOOTER = true;
export const SHOW_VIRTUAL_PET = true;
export const SHOW_ADD_MESSAGE = true;
export const SHOW_VIDEO_MESSAGE = true;

/* Checking Better Comments Plugin for VS Code
* Bugs in the Scroll Text
TODO: Make better color adjustments to the background and text. 
! When scrolled the text should disapper and not go behind header. 
? Make the text circular continuous rotate. 
 */
export const SHOW_SCROLL_TEXT = false;
