import "./OrdersPage.css";
import React from "react";

function OrdersPage() {
  return (
    <div className="orders">
      <h1>You dont have any order yet.</h1>
    </div>
  );
}

export default OrdersPage;
