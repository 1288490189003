import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { PerspectiveCamera, RoundedBox, Environment, useTexture } from "@react-three/drei";
import { Physics, useSphere, useBox, usePlane } from "@react-three/cannon";
// import { BoxBufferGeometry } from 'three';
import * as THREE from "three";
import bgTexture from "./bg.jpg";
import bgTextureCross from "./cross.jpg";



const GradientBackground = () => {
  const textureLoader = new THREE.TextureLoader();
  const texture1 = textureLoader.load(bgTexture);
  const texture2 = textureLoader.load(bgTexture);
  const texture3 = textureLoader.load(bgTexture);
  const texture4 = textureLoader.load(bgTexture);
  const texture5 = textureLoader.load(bgTexture);
  const texture6 = textureLoader.load(bgTexture);


  return (
    <mesh rotation={[0, 0, 0]}>
      <boxGeometry args={[100, 100, 100]} />
      <meshBasicMaterial attachArray="material" map={texture1} side={THREE.BackSide} />
      <meshBasicMaterial attachArray="material" map={texture2} side={THREE.BackSide} />
      <meshBasicMaterial attachArray="material" map={texture3} side={THREE.BackSide} />
      <meshBasicMaterial attachArray="material" map={texture4} side={THREE.BackSide} />
      <meshBasicMaterial attachArray="material" map={texture5} side={THREE.BackSide} />
      <meshBasicMaterial attachArray="material" map={texture6} side={THREE.BackSide} />
    </mesh>
  );
};

function BallAndCollisions({ args = [1.2, 32, 32], v = new THREE.Vector3() }) {
  const cam = useRef();
  const textureCross = useTexture(bgTextureCross);
  const [ref, api] = useSphere(() => ({ args: [1.2], mass: 1, material: { restitution: 0.95 } }));
  usePlane(() => ({
    position: [0, -15, 0],
    rotation: [-Math.PI / 2, 0, 0],
    onCollide: () => {
      api.position.set(0, 0, 0);
      api.velocity.set(0, 0, 0);
    },
  }));
  usePlane(() => ({ position: [-15, 0, 0], rotation: [-Math.PI / 2, Math.PI / 2, 0] }));
  usePlane(() => ({ position: [15, 0, 0], rotation: [Math.PI / 2, -Math.PI / 2, 0] }));

  useEffect(() => {
    const unsubscribe = api.position.subscribe((p) => {
      const ballX = p[0];
      const ballY = p[1];
      const ballZ = p[2];

      // Calculate the camera position based on the ball's position
      const cameraX = ballX * -1; // Invert the movement for camera
      const cameraY = ballY;
      const cameraZ = 18 + Math.max(0, ballY) / 2;

      cam.current.position.lerp(v.set(cameraX, cameraY, cameraZ), 0.05);
      cam.current.lookAt(ballX, ballY, ballZ);
    });

    return () => unsubscribe();
  },);

  return (
    <>
      <PerspectiveCamera ref={cam} makeDefault position={[0, 0, 12]} fov={50} />
      <mesh ref={ref}>
        <sphereGeometry args={args} />
        <meshStandardMaterial map={textureCross} metalness={1} roughness={0} />
      </mesh>
    </>
  );
}

const Block = forwardRef(({ shake = 0, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props }, ref) => {
  const group = useRef();
  const [block, api] = useBox(() => ({
    args,
    ...props,
    // onCollide: (e) => {
    //   shake += e.contact.impactVelocity / 12.5;
    // },
  }));
  useFrame(() => {
    group.current.position.lerp(vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0), 0.2);
  });
  useImperativeHandle(ref, () => api, [api]);
  return (
    <group ref={group}>
      <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
        <meshPhysicalMaterial transmission={1} roughness={0} thickness={3} envMapIntensity={4} />
      </RoundedBox>
    </group>
  );
});

function Paddle({ args = [5, 1.5, 4] }) {
  const api = useRef();
  useFrame((state) => {
    api.current.position.set(state.mouse.x * 10, -5, 0);
    api.current.rotation.set(0, 0, (state.mouse.x * Math.PI) / 4);
  });
  return <Block ref={api} args={args} material={{ restitution: 1.3 }} />;
}

function MovingBlock({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) => {
    api.current.position.set(x + (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) / 4, y, z);
  });
  return <Block ref={api} args={[3, 1.5, 4]} material={{ restitution: 1.1 }} {...props} />;
}

const GameComponent = () => {
  return (
    <Canvas style={{ height: "672px" }}>
      <GradientBackground />
      <ambientLight intensity={0.3} />
      <spotLight intensity={0.7} position={[30, 30, 50]} angle={0.2} penumbra={1} />
      <Physics gravity={[0, -30, 0]} broadphase="SAP">
        <BallAndCollisions />
        <Paddle />
        <MovingBlock offset={0.2} position={[0, 5, 0]} />
        <MovingBlock offset={Math.PI} position={[0, 10, 0]} />
        <Environment preset="sunset" />
      </Physics>
    </Canvas>
  );
};

export default GameComponent;
