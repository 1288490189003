/* eslint-disable */
// this is an auto generated file. This will be overwritten

// createdAt
// updatedAt
// _version
// _deleted
// _lastChangedAt

export const getClothingProducts = /* GraphQL */ `
  query GetClothingProducts($id: ID!) {
    getClothingProducts(id: $id) {
      id
      SKU
      Name
      Description
      Price
      Category
      Brand
      Image
      Color
      Size
      Gender
      Inventory
      CreatedAt
      UpdatedAt
    }
  }
`;
export const listClothingProducts = /* GraphQL */ `
  query ListClothingProducts(
    $filter: ModelClothingProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClothingProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SKU
        Name
        Description
        Price
        Category
        Brand
        Image
        Color
        Size
        Gender
        Inventory
        CreatedAt
        UpdatedAt
        IsVisible
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClothingProducts = /* GraphQL */ `
  query SyncClothingProducts(
    $filter: ModelClothingProductsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClothingProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SKU
        Name
        Description
        Price
        Category
        Brand
        Image
        Color
        Size
        Gender
        Inventory
        CreatedAt
        UpdatedAt
      }
      nextToken
      startedAt
    }
  }
`;
