import "./CollabrationPage.css";
import React from "react";

function CollabrationPage() {
  return (
    <div className="collabration">
    <div className="row rowTemp">
      <h1 className="colabHeader col-sm-2 col-12"> Collaborate with us </h1>
    </div>
    <div className="row rowTemp">
      <span className="colabSubHeader col-sm-3 col-12">Please fill out the form below so the
appropriate representative from our team can contact you.</span>
    </div>
    <div className="row rowTemp">
      <span className="colabWarn col-sm-3 col-12">All fields are required unless marked optional.</span>
    </div>
      <form className="formRow">
        <div className="row rowTemp">
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">First Name</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="Ada"
              name="firstName"
              type="text"/>
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Last Name</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="Lovelace"
              name="lastName"
              type="text"/>
          </div>
        </div>
        <div className="row rowTemp">
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Email Address</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="lovelace@example.com"
              name="emailAddress"
              type="text"/>
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Confirm Email Address</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="lovelace@example.com"
              name="cnfrmEmail"
              type="text"/>
          </div>
        </div>
        <div className="row rowTemp">
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Inquiry Type</label>
            <select
              className="colabInput col-sm-12 col-10"
              name="inquiryType">
                <option value="collab">Business collaborations</option>
              </select>
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Phone Number(Optional)</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="000-123-4567"
              name="number"
              type="text"/>
          </div>
        </div>
        <div className="row rowTemp">
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Organization name</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="Business name"
              name="number"
              type="text"/>
          </div>
          <div className="col-md-3 col-sm-3 col-12">
            <label className="colabLabel">Your allias</label>
            <input
              className="colabInput col-sm-12 col-10"
              placeholder="@lovelace"
              name="number"
              type="text"/>
          </div>
        </div>
        <div className="row rowTemp">
          <div className="col-md-6 col-sm-6 col-12">
            <label className="colabLabel">Message</label>
            <input
              className="colabInput colabInput1 col-sm-12 col-10"
              placeholder="Type your message here"
              name="message"
              type="text"
            />
          </div>
        </div>
        <div className="row rowTemp">
          <div className="col-md-3 col-sm-3 col-12">
            <br/>
            <input
              className=""
              placeholder=""
              name="number"
              type="checkbox"
            />
            <span className="colabAgreement col-sm-12 col-10"> By submitting this form, I acknowledge that I
            have read and understand the <span className="">Privacy Statement</span></span>
          </div>
          <div className="col-md-3 col-sm-3 col-12"><br/>
          <button className="submitBtn form-control btn btn-primary col-sm-12 col-10" type="submit">
          Submit
        </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CollabrationPage;
