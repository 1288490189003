import React from "react";
import "./ProductPage.css";
import { Box, Grid } from "@mui/material";
import ProductItems from "../ui-components/ProductItems";
import { useEffect, useState } from "react";
import fetchProductsFromDDB from "../models/fetchProducts";

function ProductPage() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function loadData() {
      // TODO: Change to query in future
      const data = await fetchProductsFromDDB();
      setProducts(data);
    }
    loadData();
  }, []);
  //Filters
  const [selectedFilters, setSelectedFilters] = useState({
    Category: [],
    Color: [],
    Size: [],
  });

  const handleFilterChange = (filterName, selectedValues) => {
    if (selectedValues.includes("")) {
      selectedValues = selectedValues.filter((value) => value !== "");
    }
    setSelectedFilters({
      ...selectedFilters,
      [filterName]: selectedValues,
    });
  };

  const filteredData = products.filter((obj) => {
    return Object.entries(selectedFilters).every(
      ([filterName, selectedValues]) => {
        if (selectedValues.length === 0) return true; // No filter selected, include all items
        return selectedValues.includes(obj[filterName]);
      }
    );
  });

  // Sort
  const [selectedSort, setSelectedSort] = useState("");

  const handleSortChange = (selectedValue) => {
    setSelectedSort(selectedValue);
  };

  const sortedData = [...filteredData];

  if (selectedSort === "priceLowToHigh") {
    sortedData.sort((a, b) => a.Price - b.Price);
  } else if (selectedSort === "priceHighToLow") {
    sortedData.sort((a, b) => b.Price - a.Price);
  }

  if (products.length <= 7) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* log.console("Loading Product Page"); */}
      <div className="Banner">{/* TODO: Fix banner height */}</div>
      <div className="row filter">
        <div className="selectDiv col-sm-3 col-4">
          <select
            value={selectedFilters.Category}
            onChange={(event) =>
              handleFilterChange(
                "Category",
                Array.from(
                  event.target.selectedOptions,
                  (option) => option.value
                )
              )
            }
          >
            <option value="">Categories</option>
            {[...new Set(products.map((item) => item.Category))].map(
              (category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              )
            )}
          </select>
        </div>
        <div className="selectDiv col-sm-3 col-4">
          <select
            value={selectedFilters.Color}
            onChange={(event) =>
              handleFilterChange(
                "Color",
                Array.from(
                  event.target.selectedOptions,
                  (option) => option.value
                )
              )
            }
          >
            <option value="">Colors</option>
            {[...new Set(products.map((item) => item.Color))].map((color) => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </select>
        </div>
        <div className="selectDiv col-sm-3 col-4">
          <select
            value={selectedFilters.Size}
            onChange={(event) =>
              handleFilterChange(
                "Size",
                Array.from(
                  event.target.selectedOptions,
                  (option) => option.value
                )
              )
            }
          >
            <option value="">Sizes</option>
            {[...new Set(products.map((item) => item.Size))].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="selectDiv col-sm-3 col-4">
          <select
            value={selectedSort}
            onChange={(event) => handleSortChange(event.target.value)}
          >
            <option value="">No Sort</option>
            <option value="priceLowToHigh">Price: Low to High</option>
            <option value="priceHighToLow">Price: High to Low</option>
          </select>
        </div>
      </div>
      {/* xs={6} - 2 components, sm={4} - 3 components, md={3} - 4 components, lg={3} - 4 components, xl={2} - 6 components */}
      <div className="products">
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          {sortedData.map((product, index) => (
            <Grid key={index} item xs={6} sm={4} md={3} lg={3} xl={2}>
              <Box display="flex" justifyContent="center">
                <ProductItems clothingProducts={product}></ProductItems>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default ProductPage;
