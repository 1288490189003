import React from "react";
import bgVideo from "../assets/Foggy_Day_11MB.mp4";
import "./BackgroundVideo.css";

const BackgroundVideo = () => {
  return (
    <section className="showcase">
      <div className="video-container">
        <video
          src={bgVideo}
          className="bv-video"
          autoPlay
          loop
          muted
          style={{ objectFit: "cover", width: "100%", height: "100vh" }}
        ></video>
      </div>
    </section>
  );
};

export default BackgroundVideo;
