import React from 'react'
import { Link } from "react-router-dom";
import './HomeRowCart.css'
import shopLogo from '../assets/shopImage.png'
const HomeRowCart = () => {
  return (

    <div className='sectionDivtow'><div className='row mr-0 ml-0 productHomeDiv ' style={{  }}>
    <div className='row m-0 productHomeDivChild' style={{}}>
      <h1 className='col-12 col-sm-12 mt-0 productVisit'>Visit our Shop!</h1>
      <div className=' col-12 col-sm-12 productVisit'> <Link to='shop'>Shop></Link> <Link to='game'>Game></Link> </div>
      <div className='col-12 col-sm-12 productVisit mt-4'>
        <Link to="shop">
          <img className='shopLogo' src={shopLogo} alt='Shop'/>
        </Link>
      </div>
    </div>

  </div></div>
  )
}

export default HomeRowCart