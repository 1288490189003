import React from 'react';
import './formStyles.css'
export const Form = ({ 
  onSubmit,
  closeModal }) => {
  return (
    <form onSubmit={onSubmit}>
    <h2 className='popUpLabel'>Edit Your Shipping Address</h2>
      <div className="form-group">
        <input placeholder='First Name' className="form-control inputTag" id="fname" />
      </div>
      <div className="form-group">
        <input placeholder='Last Name' className="form-control inputTag" id="lname" />
      </div>
      <div className="form-group">
        <input placeholder='Company Name (Optional)' className="form-control inputTag" id="companyName" />
      </div>
      <div className="form-group">
        <input placeholder='Street Address' className="form-control inputTag" id="address1" />
      </div>
      <div className="form-group">
        <input placeholder='Apt, Suite, Building (Optional)' className="form-control inputTag" id="address2" />
      </div>
      <div className='fromRowaccoutn row'>
        <div className="col-sm-4 col-4 form-group">
          <input placeholder='Zip Code' className="form-control inputTag" id="zip" />
        </div>
        <div className="col-sm-4 col-4  form-group">
          <input placeholder='City' className="form-control inputTag" id="city" />
        </div>
        <div className=" col-sm-4 col-4 form-group">
          <input placeholder='State' className="form-control inputTag" id="state" />
        </div>
      </div>
      <div className="form-group">
          <input placeholder='Country/Region' value="United State" disabled className="form-control inputTag" id="country" />
      </div>
      <div className="form-group numberTag">
          <input placeholder='Phone Number' type='number' pattern="[0-9]{5}[-][0-9]{7}[-][0-9]{1}" className="form-control inputTag" id="number" />
      </div>
      <div className='subText'>
        <span className='phoneWarning'>The phone number you enter can't be changed after you place your order, so 
        please make sure it's correct.</span>
      </div>
      <div className="submitTag form-group">
        <button className="submitBtn form-control btn btn-primary" type="submit">
          Submit
        </button>
      </div>
      <div className='cancelTag'>
        <span className='cancelTxt' onClick={closeModal}>Cancel</span>
      </div>
    </form>
  );
};
export default Form;
