import React from 'react'
import { useEffect, useRef } from 'react';
import { Application } from '@splinetool/runtime';
import './HomeSecondRow.css'

export const HomeSecondRow = ({ sceneUrl }) => {
    const canvasRef = useRef(null);
    let app;

    useEffect(() => {
        const canvas = canvasRef.current;
        app = new Application(canvas);
        app.load(sceneUrl);

        return () => {
            app.dispose();
        };
    }, [sceneUrl]);

    return (
        <div className='row ml-0 mr-0 canvasStylesHome ' style={{}}>
            <canvas ref={canvasRef} style={{ display: "block" }} />
        </div>
    )
}
