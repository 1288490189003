import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/danny_model/Danny_Rig_Blink_Working.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_pants_buttons.geometry}
        material={materials.dan_button}
        position={[0, 0.028, 0]}
        rotation={[1.534, 0.001, 0]}
        scale={0.969}
      />
      <mesh
        name="dan_Body"
        castShadow
        receiveShadow
        geometry={nodes.dan_Body.geometry}
        material={materials.dan_skin_2}
        morphTargetDictionary={nodes.dan_Body.morphTargetDictionary}
        morphTargetInfluences={nodes.dan_Body.morphTargetInfluences}
        position={[0, 0.028, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_eyebrow.geometry}
        material={materials.dan_eyebrows}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_eyelash.geometry}
        material={materials.dan_dark}
      />
      <mesh
        name="dan_hairmat"
        castShadow
        receiveShadow
        geometry={nodes.dan_hairmat.geometry}
        material={materials.dan_dark}
        morphTargetDictionary={nodes.dan_hairmat.morphTargetDictionary}
        morphTargetInfluences={nodes.dan_hairmat.morphTargetInfluences}
        position={[0, 0.028, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_pants_detail.geometry}
        material={materials.dan_pants}
        position={[0, 0.028, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_pants_main.geometry}
        material={materials.dan_pants}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_shirt_1.geometry}
        material={materials.dan_shirt_01}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_shirt_2.geometry}
        material={materials.dan_bra_1}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <group position={[-0.135, 0.056, 0.068]} rotation={[0, -0.038, Math.PI]}>
        <mesh
          name="RetopoFlow008"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow008.geometry}
          material={materials.dan_sneaker_strap}
          morphTargetDictionary={nodes.RetopoFlow008.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow008.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow008_1"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow008_1.geometry}
          material={materials.dan_Shoe}
          morphTargetDictionary={nodes.RetopoFlow008_1.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow008_1.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow008_2"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow008_2.geometry}
          material={materials.dan_Sneaker_Sole}
          morphTargetDictionary={nodes.RetopoFlow008_2.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow008_2.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow008_3"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow008_3.geometry}
          material={materials.dan_shoeRubber}
          morphTargetDictionary={nodes.RetopoFlow008_3.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow008_3.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow008_4"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow008_4.geometry}
          material={materials.dan_Metal}
          morphTargetDictionary={nodes.RetopoFlow008_4.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow008_4.morphTargetInfluences}
        />
      </group>
      <group rotation={[0, 0.037, -Math.PI]}>
        <mesh
          name="RetopoFlow001"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow001.geometry}
          material={materials.dan_sneaker_strap}
          morphTargetDictionary={nodes.RetopoFlow001.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow001.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow001_1"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow001_1.geometry}
          material={materials.dan_Shoe}
          morphTargetDictionary={nodes.RetopoFlow001_1.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow001_1.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow001_2"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow001_2.geometry}
          material={materials.dan_Sneaker_Sole}
          morphTargetDictionary={nodes.RetopoFlow001_2.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow001_2.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow001_3"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow001_3.geometry}
          material={materials.dan_shoeRubber}
          morphTargetDictionary={nodes.RetopoFlow001_3.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow001_3.morphTargetInfluences}
        />
        <mesh
          name="RetopoFlow001_4"
          castShadow
          receiveShadow
          geometry={nodes.RetopoFlow001_4.geometry}
          material={materials.dan_Metal}
          morphTargetDictionary={nodes.RetopoFlow001_4.morphTargetDictionary}
          morphTargetInfluences={nodes.RetopoFlow001_4.morphTargetInfluences}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_tongue.geometry}
        material={materials.dan_red}
        position={[0, 0.028, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo.geometry}
        material={materials.dan_Simple_Hair}
        scale={0.841}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo001.geometry}
        material={materials.dan_Simple_Hair}
        scale={0.841}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo002.geometry}
        material={materials.dan_Simple_Hair}
        scale={0.841}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo003.geometry}
        material={materials.dan_Simple_Hair}
        position={[-0.001, 0.248, 0.037]}
        scale={0.841}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo004.geometry}
        material={materials.dan_Simple_Hair}
        scale={0.841}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo005.geometry}
        material={materials.dan_Simple_Hair}
        scale={0.841}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dan_hairgeo006.geometry}
        material={materials.dan_Simple_Hair}
        scale={0.841}
      />
      <group position={[0, 1.557, 0.14]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Untitled001.geometry}
          material={materials.dan_white}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Untitled001_1.geometry}
          material={materials.dan_red}
        />
      </group>
      <group
        position={[0.036, 1.609, 0.1]}
        rotation={[-0.065, 0.066, 0.001]}
        scale={0.892}
      >
        <mesh
          name="Auto_Eye_SCLERA001"
          castShadow
          receiveShadow
          geometry={nodes.Auto_Eye_SCLERA001.geometry}
          material={materials.dan_cornea}
          morphTargetDictionary={nodes.Auto_Eye_SCLERA001.morphTargetDictionary}
          morphTargetInfluences={nodes.Auto_Eye_SCLERA001.morphTargetInfluences}
        />
        <mesh
          name="Auto_Eye_SCLERA001_1"
          castShadow
          receiveShadow
          geometry={nodes.Auto_Eye_SCLERA001_1.geometry}
          material={materials.dan_cornea_PupilVis}
          morphTargetDictionary={
            nodes.Auto_Eye_SCLERA001_1.morphTargetDictionary
          }
          morphTargetInfluences={
            nodes.Auto_Eye_SCLERA001_1.morphTargetInfluences
          }
        />
        <mesh
          name="iris1"
          castShadow
          receiveShadow
          geometry={nodes.iris1.geometry}
          material={materials.dan_Iris}
          morphTargetDictionary={nodes.iris1.morphTargetDictionary}
          morphTargetInfluences={nodes.iris1.morphTargetInfluences}
        />
      </group>
      <group
        position={[-0.036, 1.609, 0.1]}
        rotation={[-0.065, -0.066, -0.008]}
        scale={0.892}
      >
        <mesh
          name="Auto_Eye_SCLERA001"
          castShadow
          receiveShadow
          geometry={nodes.Auto_Eye_SCLERA001.geometry}
          material={materials.dan_cornea}
          morphTargetDictionary={nodes.Auto_Eye_SCLERA001.morphTargetDictionary}
          morphTargetInfluences={nodes.Auto_Eye_SCLERA001.morphTargetInfluences}
        />
        <mesh
          name="Auto_Eye_SCLERA001_1"
          castShadow
          receiveShadow
          geometry={nodes.Auto_Eye_SCLERA001_1.geometry}
          material={materials.dan_cornea_PupilVis}
          morphTargetDictionary={
            nodes.Auto_Eye_SCLERA001_1.morphTargetDictionary
          }
          morphTargetInfluences={
            nodes.Auto_Eye_SCLERA001_1.morphTargetInfluences
          }
        />
        <mesh
          name="dan_iris2"
          castShadow
          receiveShadow
          geometry={nodes.dan_iris2.geometry}
          material={materials.dan_Iris}
          morphTargetDictionary={nodes.dan_iris2.morphTargetDictionary}
          morphTargetInfluences={nodes.dan_iris2.morphTargetInfluences}
        />
      </group>
      <group position={[0, 1.547, 0.137]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Untitled002.geometry}
          material={materials.dan_white}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Untitled002_1.geometry}
          material={materials.dan_red}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/static-danny.gltf");
