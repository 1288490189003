import React from "react";
import "./CreateItem.css";


function CreateItem() {
  return (
    <div class="CreateItem">
      <h1>Create Item</h1>
    </div>
  );
}

export default CreateItem;
