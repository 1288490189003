import React from "react";
import { connect } from "react-redux";
import { removeFromCart, updateQuantity, clearCart } from "../redux/cartSlice";
// import { CartTile } from "../ui-components";
import CartItem from "./CartItems";
import { useSelector } from "react-redux";
import { selectCartTotalCount } from "../redux/cartSlice";
import { useDispatch } from 'react-redux';
//Authenticate the user
import { withAuthenticator } from "@aws-amplify/ui-react";
// import { Auth } from "aws-amplify";
import "./Cartitems.css"
// import { selectAuthenticated } from "../redux/auth";
// import { setAuthenticated, setUnauthenticated } from "../redux/auth";
import './CartPage.css'
// Define the cart component
function CartPage({ cart, clearCart }) {
  const dispatch = useDispatch();


  const clearBag = () => {
    dispatch(clearCart());
  }
  const totalCount = useSelector(selectCartTotalCount);
  console.log("Loading CartPage", cart);
  console.log(cart.length)
  
  const actualAmount = cart.length>1 ? cart.reduce((first,second) => {
      return first + (second.Price * second.quantity);
    },0) : cart.length === 1 ? cart[0].Price * cart[0].quantity : 0;
  const discountAmount =0.2*actualAmount;
  const totalAmount = actualAmount - discountAmount;
  // console.log(actualAmount,'acuta')
  console.log("Total items in cart: ", totalCount);
  return (
    <div className="cartPageInit">
      {cart.length === 0 ? (
        <p className="cartEmpty">Your cart is empty.</p>
      ) : (
        <div>
          <h2> Cart </h2>
          {cart.map((product) => (
            <CartItem clothingProducts={product}></CartItem>
          ))}

        </div>
      )}
      {cart.length !== 0 ?
      (<div className="btnBag removeFooterMargin row">
        <button className="btn btn-danger" onClick={ clearBag}>Clear Cart</button>
      </div>):("")}
      {cart.length !== 0 ?
      (<div className='removeFooterMargin row'>
                <div className='col-sm-8'>
                </div>
                <div className='col-sm-4'>
                    <div className='textAlign removeFooterMargin row'>
                        <div className='col-sm-6 col-6'>
                            <span>Sub Total : </span>
                        </div>
                        <div className='col-sm-6 col-6'>
                            <span>$ {actualAmount}</span>
                        </div>
                    </div>
                    <div className="textAlign discount removeFooterMargin row">
                        <div className='col-sm-6 col-6'>
                            <span> Discount : </span>
                        </div>
                        <div className='col-sm-6 col-6'>
                            <span>$ {discountAmount}</span>
                        </div>
                    </div>
                    <div className="textAlign price2 removeFooterMargin row">
                        <div className='col-sm-6 col-6'>
                            <span>Total : </span>
                        </div>
                        <div className='col-sm-6 col-6'>
                            <span>$ {totalAmount}</span>
                        </div>
                    </div>
                    <div className="btnPad removeFooterMargin row">
                        <button className="col-sm-12 btn-color btn-primary btn-lg btn-block">Checkout Now</button>
                    </div>
                </div>
            </div>):("")}
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log("cart state:", state.cart);
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = { removeFromCart, updateQuantity, clearCart };
const ConnectedCart = connect(mapStateToProps, mapDispatchToProps)(CartPage);

export default withAuthenticator(ConnectedCart);
