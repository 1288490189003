import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './StockTreeMap.css';

const StockTreeMap = () => {
    const ref = useRef();
    // Define width and height as constants for easy modification
    const width = 1200;
    const height = 600;

    useEffect(() => {
      const svg = d3.select(ref.current)
        .selectAll('svg')
        .data([null])
        .join('svg')
        .attr('width', width)
        .attr('height', height + 80)
        .style("font", "10px sans-serif");
        

      const g = svg.selectAll('g').data([null]).join('g');

      let currentZoom = 1; 

      // Function to update text visibility and size based on zoom and rectangle size
      function updateTextVisibility() {
        g.selectAll("text")
            .attr("visibility", function (d) {
                const rect = this.parentNode.querySelector("rect");
                const rectWidth = rect.width.baseVal.value;
                const rectHeight = rect.height.baseVal.value;
                const textWidth = this.getBBox().width;
                const textHeight = this.getBBox().height;
                return (textWidth * currentZoom <= rectWidth && textHeight * currentZoom <= rectHeight) ? "visible" : "hidden";
            })
            .style("font-size", function (d) {
                const rect = this.parentNode.querySelector("rect");
                const scaleFactor = Math.min(rect.width.baseVal.value / this.getBBox().width, rect.height.baseVal.value / this.getBBox().height);
                const newSize = Math.floor(10 * Math.min(2, scaleFactor * currentZoom)); // Adjust base size to your preference
                return `${newSize}px`;
            });
        }

      svg.call(d3.zoom()
          .scaleExtent([1, 8])
          .on('zoom', (event) => {
            currentZoom = event.transform.k;
            g.attr('transform', event.transform);
            updateTextVisibility();
          }));

      const fetchAndDraw = async () => {
          const response = await fetch('/data/data.json');
          const data = await response.json();
          drawTreeMap(g, data);
      };

      const drawTreeMap = (g, data) => {
        const treemap = d3.treemap()
          .size([width, height])
          .paddingOuter(3);

        const root = d3.hierarchy(data)
          .sum(d => d.value)
          .sort((a, b) => b.value - a.value);
  
        treemap(root);

        // Ensure all previous elements in 'g' are removed before appending new elements
        g.selectAll("*").remove();

        const leaf = g.selectAll("g")
            .data(root.leaves())
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        leaf.append("rect")
            .attr("id", d => (d.leafUid = `leaf-${d.data.name}`))
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0)
            .attr("fill-opacity", 0.6)
            .attr("fill", d => getColor(d.data.change))
            .attr("stroke", "black")
            .attr("stroke-width", "1px");

        // leaf.append("clipPath")
        //     .attr("id", d => (d.clipUid = `clip-${d.data.name}`))
        //     .append("use")
        //     .attr("xlink:href", d => d.leafUid.href);

        leaf.append("text")
          .attr("clip-path", d => `url(#clip-${d.data.name})`)
          .selectAll("tspan")
          .data(d => d.data.name.split(/(?=[A-Z][^A-Z])/g))
          .join("tspan")
          .attr("x", 3)
          .attr("y", (d, i) => `${i * 1.1 + 1}em`)
          .text(d => d);

        updateTextVisibility();
      };

      const legendData = [
        { color: 'green', text: '>1% Gain' },
        { color: 'grey', text: 'Stable' },
        { color: 'red', text: '<-1% Loss' }
      ];
      
      const legend = svg.append("g")
        .attr("transform", `translate(10, ${height + 10})`);
      
      legend.selectAll("rect")
        .data(legendData)
        .join("rect")
        .attr("x", 0)
        .attr("y", (d, i) => i * 20)
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", d => d.color);
      
      legend.selectAll("text")
        .data(legendData)
        .join("text")
        .attr("x", 15)
        .attr("y", (d, i) => i * 20 + 9)
        .text(d => d.text)
        .style("font-size", "12px")
        .attr("fill", "black");
      

        fetchAndDraw();
    }, []);

    return (
        <div ref={ref} className='stocktreemap'>StockTreeMap</div>
    );
};

export default StockTreeMap;

function getColor(change) {
  if (change < -1) return 'red';
  else if (change > 1) return 'green';
  else return 'grey';
}