import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const FooterInBuild = () => {
  return (
    <div>
      <h1>Site still in build.</h1>
      <p>Please check bag later to use this functionality. </p>
    </div>
  );
};

export const AboutUs = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Image src="your_logo.png" fluid />
          <h1>About Us</h1>
          <p>Your company description goes here</p>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterInBuild;
