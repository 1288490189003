import React, { useState, useRef, useEffect }from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { selectCartTotalCount, selectCartItems } from "../redux/cartSlice";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice";
// import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { selectAuthenticated } from "../redux/auth";
import { setAuthenticated, setUnauthenticated } from "../redux/auth";
function Header(props) {
  const dispatch = useDispatch();
  // Cart Icon Related Code
  const cartTotalCount = useSelector(selectCartTotalCount);
  const cartItemsList = useSelector(selectCartItems);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBagShown, setIsBagShown] = useState(false);
  const [isOverlayShown, setIsOverlayShown] = useState(false);
  const [isAriaExpanded, setIsAriaExpanded] = useState(false);
  const searchEl = useRef(null)
console.log(cartItemsList,'itemList');
  const authenticated = useSelector(selectAuthenticated);
  useEffect(() => {
    checkAuth();
  }, );

async function checkAuth() {
  const user = await Auth.currentAuthenticatedUser();
  if (user) {
    // setAuthenticated(true);
    dispatch(setAuthenticated());
  } else {
    // setAuthenticated(false);
    dispatch(setUnauthenticated());
    console.log("User is not authenticated");
  }
}
const data = window.location.href

  const handleBagClick = (event) => {
    // 👇️ toggle visibility
    setIsBagShown(!isBagShown);
    //overrlay
    setIsOverlayShown(!isOverlayShown);
  };
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    if (props.product) {
      setProductData(props.product);
    }
  }, [props.product]);
  

  const cartRef = useRef(null);

  const handleBagHover = () => {
    setIsBagShown(!isBagShown);
  };


  const handleBagLeave = (event) => {
    const relatedTarget = event.relatedTarget;
  const header = document.getElementById("header-bar");
  const navLeftIn = document.getElementById("nav-left-interior")
  const navMidIn = document.getElementById("nav-mid-interior")
  const navRightIn = document.getElementById("nav-right-interior")
  const cart = document.getElementsByClassName("nav-cart-item")[0];
  const navItem = document.getElementById("navsearchIn")
  const navItem1 = document.getElementById("navsearch1")
  if (
    !cartRef.current.contains(relatedTarget) &&
    relatedTarget !== header &&
    relatedTarget !== cart && relatedTarget !== navLeftIn
    && relatedTarget !== navMidIn && relatedTarget !== navRightIn
    && relatedTarget !== navItem && relatedTarget !== navItem1 
  ) {
    setIsBagShown(false);
    setIsOverlayShown(false);
  }
  };
  const handleMerchMenuToggle = () => {
  };

  useEffect(() => {
    setIsAriaExpanded(isMenuOpen);
  }, [isMenuOpen]);

  // Search 🔍 related code
  const [isShown, setIsShown] = useState(true);
  const handleClick = (event) => {
    // 👇️ toggle visibility
    setIsShown((current) => !current);

    if(isShown){
      setIsBagShown(false);
      setIsOverlayShown(true);
    }
    else{
      setIsOverlayShown(false);
    }
  };

  const handleOverlayClick = () => {
      setIsOverlayShown(false);
      setIsShown(true)   
  };

  const [searchProducts,setSearchProducts] = useState("");
  const handleSearchItem = (inputValue) => {
    const searchKey = inputValue.target.value.toLowerCase();
    try{
      const filteredData = productData.filter(
        (prod) => prod.Name.toLowerCase().match(searchKey)
        )
        if(searchKey === "")
        setSearchProducts("")
        else
        setSearchProducts(filteredData)
        console.log(Object.keys(searchProducts).length,'fetched filter')
    }
    catch(err){
      console.log(err)
    }
    
  } 
  const searchItemAdd = (item) => {
    dispatch(addToCart(item))
  }

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      // setAuthenticated(false)
      dispatch(setUnauthenticated());
      localStorage.removeItem("chatMessages")
      console.log("User signed out");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  // Hamberger Menu Options
  React.useEffect(() => {
    let x = document.getElementById("nav-left-interior");
    let middle_nav = document.getElementById("nav-middle");
    let right_nav = document.getElementById("nav-right");
    let header = document.getElementById("header-bar");
    let checkbox = document.getElementById('check'); // Get a reference to the checkbox
    if (isMenuOpen) {
      x.style.background = "black";
      x.className = "nav-left-interior responsive";
      middle_nav.style.display = "none";
      right_nav.style.display = "none";
      header.style.background = "black";
      checkbox.checked = true; // Check the checkbox
    } else {
      x.style.background = "none";
      x.className = "nav-left-interior listIt";
      middle_nav.style.display = "block";
      right_nav.style.display = "block";
      header.style.background = "#000000cc";
      checkbox.checked = false; // Uncheck the checkbox
    }
  }, [isMenuOpen]);
  

  return (
    <div className="header-global">
      {isOverlayShown && <div className="overlay" onClick={handleOverlayClick}></div>}
      <div className="header-bar" id="header-bar">
        <nav
          className="nav-left desktop-only" id="nav-left"
          style={{ display: isShown ? "block" : "none" }}
        >
          <ul className="nav-left-interior listIt" id="nav-left-interior">
            <li className="nav-item icon" id="changeIcon">
                <label htmlFor="check">
                <input type="checkbox" id="check" aria-expanded={isAriaExpanded} onChange={(e) => setIsMenuOpen(e.target.checked)}/> 

                  <span className="top-line"></span>
                  <span className="middle-line"></span>
                  <span className="bottom-line"></span>
                </label>

            </li>
            <li className="nav-item nav-shop has-secondary" id="nav-item" onClick={() => setIsMenuOpen(false)}>
              <Link to="/shop" onMouseEnter={handleMerchMenuToggle}>
                Merch
              </Link>
            </li>
            <li className="nav-item nav-blog" id="nav-item" onClick={() => setIsMenuOpen(false)}>
              <Link to="/story">Story</Link>
            </li>
            <li className="nav-item nav-experiment" id="nav-item" onClick={() => setIsMenuOpen(false)}>
              <Link to="/collab">Collab</Link>
            </li>
          </ul>
        </nav>

        <nav
          className="nav-middle desktop-only"
          id="nav-middle"
          style={{ display: isShown ? "block" : "none" }}
        >
          <ul className="nav-middle-interior" id="nav-mid-interior">
            <li className="nav-item nav-home-page">
              <Link to="/"> Brand Logo </Link>
            </li>
          </ul>
        </nav>

        <nav
          className="nav-right desktop-only"
          id="nav-right"
          style={{ display: isShown ? "block" : "none" }}
        >
          <ul className="nav-right-interior" id="nav-right-interior">
            <li className="nav-item top-level nav-search-item has-secondary" id="navsearch1">
              <div className="nav-search" id="navsearchIn" onClick={handleClick}></div>
            </li>
            <li className="nav-item top-level nav-cart-item">
              <div
                className="cart-container-wrapper"
                onClick={handleBagHover}
                onMouseLeave={handleBagLeave}
                ref={cartRef}
              >
                <div className="nav-bag" onClick={handleBagClick}>
                  <span className="ac-gn-bag-badge" aria-hidden="false">
                    {cartTotalCount >= 0 ? (
                      <>
                        <span className="ac-gn-bag-badge-separator"></span>
                        <span className="ac-gn-bag-badge-number">
                          {cartTotalCount}
                        </span>
                      </>
                    ) : null}
                  </span>
                </div>

                <div
                  className="cart-container"
                  style={{ display: isBagShown ? "block" : "none" }}
                  onClick={handleOverlayClick}
                >
                  <div className="quick-links">
                    <Link to="/cart">
                      <h2>Bag/Cart</h2>
                    </Link>
                    {cartItemsList && cartItemsList.length > 0 ? (
                      <ul>
                        {cartItemsList.slice(0, 3).map((item, index) => (
                          <li key={index}>
                            <a href="/">{item.Name}</a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                      <li><p>No items in cart</p></li></ul>
                    )}
                    <Link to="/profile">
                      <h4>My Profile</h4>
                    </Link>
                    <ul>
                    <li>
                        <Link to="/favorites" className="linksSvg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className=""
                            style={{  width: '15px' }}
                          >
                            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
                          </svg>
                          <span style={{paddingLeft: '10px'}}>Favorites</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/orders" className="linksSvg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className=""
                            style={{  width: '15px' }}
                          >
                            <circle cx="12" cy="12" r="10" />
                            <path d="M12 6v6l4 2" />
                          </svg>
                          <span style={{paddingLeft: '10px'}}>Orders</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/account" className="linksSvg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className=""
                            style={{  width: '15px' }}
                          >
                            <path d="M22 19c0 1.1-.9 2-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v11z" />
                            <circle cx="12" cy="13" r="4" />
                          </svg>
                          <span style={{paddingLeft: '10px'}}>Account</span>
                        </Link>
                      </li>
                      <li>{authenticated ? (
                          <a href="/" onClick={handleLogout}>Logout</a>
                        ) : (
                          <Link to={{ pathname: '/login', state: { data } }}>Login</Link>
                        )}
                        
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </li>
          </ul>
        </nav>


        {/* TODO: Add animation to remove the components */}
        <div
          className="search-container"
          style={{ display: !isShown ? "block" : "none" }}
          // overlayElement
        >
          <div className="link-search"></div>
          <div className="search-bar">
            <form action="">
            <input type="text" placeholder="Search Website"  ref={searchEl} onChange={handleSearchItem} ></input>
            </form>
          </div>
          <div className="link-close" onClick={handleClick}></div>
          {Object.keys(searchProducts).length >0 ? 
            <div className="quick-links">
              <ul>
                {
                  searchProducts.map((prod)=><div className="row searchPadding">
                  <img className="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-3" src={prod.Image} alt="product"/>
                  <li className="col-sm-6 col-6" key={prod.Name} >{prod.Name}</li>
                  <button className="btn btn-dark col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2" onClick={() => searchItemAdd(prod)}>Add</button>
                  </div>
                  )
                }
              </ul>
            </div>: <div className="quick-links">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <a href="/">Shop Online</a>
              </li>
              <li>
                <a href="/">Shop Accessories</a>
              </li>
              <li>
                <a href="/">Shop Hoodies</a>
              </li>
              <li>
                <a href="/">Latest Collection</a>
              </li>
              <li>
                <a href="/">Our Vision</a>
              </li>
            </ul>
          </div>
          }
            
        </div>

        {/* Cart Container Code */}

        {/* TODO: Add animation to remove the components */}
      </div>
    </div>
  );
}



export default Header;
