// auth.js

// Action Types
const SET_AUTHENTICATED = "SET_AUTHENTICATED";
const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";

// Action Creators
export const setAuthenticated = () => ({ type: SET_AUTHENTICATED });
export const setUnauthenticated = () => ({ type: SET_UNAUTHENTICATED });

// Reducer
const initialState = {
  authenticated: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return { ...state, authenticated: true };
    case SET_UNAUTHENTICATED:
      return { ...state, authenticated: false };
    default:
      return state;
  }
}

// Selectors
export const selectAuthenticated = (state) => state.auth.authenticated;
