import { createSlice } from "@reduxjs/toolkit";

const favoritesSlice = createSlice({
  name: "favorites",
  initialState: [],
  reducers: {
    addToFav(state, action) {
      const existingItem = state.find((i) => i.id === action.payload.id);
      if (!existingItem) {
        state.push(action.payload);
      }
    },
    removeFromFav(state, action) {
      const itemId = action.payload;
      const index = state.findIndex((item) => item.id === itemId);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addToFav, removeFromFav } = favoritesSlice.actions;
export default favoritesSlice.reducer;
