import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlogPage from "./pages/BlogPage";
import Layout from "./pages/Layout";
import CollectionPage from "./pages/CollectionPage";
import CreateItem from "./pages/CreateItem";
import OrdersPage from "./pages/OrdersPage";
import CheckoutPage from "./pages/CheckoutPage";
import ProfilePage from "./pages/ProfilePage";
import ExperimentsPage from "./pages/ExperimentsPage";
import CollabrationPage from "./pages/CollabrationPage";
import CartPage from "./pages/CartPage";
import AccountPage from "./pages/AccountPage";
import FavoritesPage from "./pages/FavoritesPage";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import FooterInBuild, { AboutUs } from "./static-pages/footer-pages";
import Header from "./ui-components/Header";
import Footer from "./ui-components/Footer";
import BuyPage from "./pages/BuyPage";
import SitemapPage from "./pages/SitemapPage";
import DeliveryPage from "./pages/DeliveryPage";
import ReturnsPage from "./pages/ReturnsPage";
import SalePage from "./pages/SalePage";
import HelpPage from "./pages/HelpPage";
import DeliveryInfoPage from "./pages/DeliveryInfoPage";
import ReturnsInfoPage from "./pages/ReturnsInfoPage";
import ReturnsPortalPage from "./pages/ReturnsPortalPage";
import AboutPage from "./pages/AboutPage";
import CorporatePage from "./pages/CorporatePage";
import CareersPage from "./pages/CareersPage";
import StoresPage from "./pages/StoresPage";
import TermsPage from "./pages/TermsPage";
import StockTreeMap from "./pages/StockTreeMap";
import PrivacyPage from "./pages/PrivacyPage";
import CookiesPage from "./pages/CookiesPage";
import Login from "./ui-components/Login";
import fetchProductsFromDDB from "./models/fetchProducts";
import { useState, useEffect } from "react";
import ProductPage from "./pages/ProductPage";
import AddMessage from "./AddMessage";
import VirtualPet from "./easter-eggs/VirtualPet";
import ScrollText from "./easter-eggs/ScrollText";
import GamePage from "./ThreeJs/GamePage";
import VideoMessage from "./VideoMessage";
import ModelComponent from "./ThreeJs/ModelComponent";
import ModelCanvas from "./ThreeJs/ModelCanvas";
import BodyRig from "./ThreeJs/BodyRig";
import {
  SHOW_HEADER,
  SHOW_FOOTER,
  SHOW_VIRTUAL_PET,
  SHOW_ADD_MESSAGE,
  SHOW_VIDEO_MESSAGE,
  SHOW_SCROLL_TEXT,
} from "./constants";

function App() {
  const [showOverlay, setShowOverlay] = useState(true);
  const [products, setProducts] = useState([]);
  const [messages, setMessages] = useState([]);

  function setOverlay(value) {
    setShowOverlay(value);
  }

  useEffect(() => {
    async function loadData() {
      try {
        const data = await fetchProductsFromDDB();
        setProducts(data);
        console.log("Products loaded successfully");
      } catch (error) {
        console.error("Error loading products:", error);
      }
    }
    loadData();
  }, []);

  useEffect(() => {
    try {
      const storedMessages = JSON.parse(localStorage.getItem("chatMessages"));
      if (storedMessages) {
        setMessages(storedMessages);
      }
      console.log("Messages loaded successfully");
    } catch (error) {
      console.error("Error loading messages:", error);
    }
  }, []);

  const addMessage = (message) => {
    try {
      const updatedMessages = [...messages, message];
      setMessages(updatedMessages);
      localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
      console.log("Message added successfully");
    } catch (error) {
      console.error("Error adding message:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newMessage = e.target.message.value;
    addMessage(newMessage);
    e.target.reset();
  };

  return (
    <Router>
      <div className="App">
        {SHOW_HEADER && (
          <Header product={products} setOverlay={{ setOverlay, showOverlay }} />
        )}
        {SHOW_SCROLL_TEXT && <ScrollText />}
        <div className="container">
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Layout />} />
              <Route path="/story" element={<BlogPage />} />
              <Route
                path="/shop"
                element={<ProductPage product={products} />}
              />
              <Route path="/collab" element={<CollabrationPage />} />
              <Route path="/addItem" element={<CreateItem />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/favorites" element={<FavoritesPage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/collection" element={<CollectionPage />} />
              <Route path="/experiments" element={<ExperimentsPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/FooterInBuild" element={<FooterInBuild />} />
              <Route path="/buy" element={<BuyPage />} />
              <Route path="/sitemap" element={<SitemapPage />} />
              <Route path="/delivery" element={<DeliveryPage />} />
              <Route path="/returns" element={<ReturnsPage />} />
              <Route path="/sale" element={<SalePage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/delivery-info" element={<DeliveryInfoPage />} />
              <Route path="/returns-info" element={<ReturnsInfoPage />} />
              <Route path="/returns-portal" element={<ReturnsPortalPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/corporate" element={<CorporatePage />} />
              <Route path="/careers" element={<CareersPage />} />
              <Route path="/stores" element={<StoresPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/cookies" element={<CookiesPage />} />
              <Route path="/game" element={<GamePage />} />
              <Route path="/danny" element={<ModelComponent />} />
              <Route path="/danny1" element={<ModelCanvas />} />
              <Route path="/rig" element={<BodyRig />} />
              <Route path="/stocktreemap" element={<StockTreeMap />} />
              <Route path="*" element={<PageNotFoundPage />} />
            </Routes>
            {SHOW_VIDEO_MESSAGE && <VideoMessage />}
            {SHOW_ADD_MESSAGE && <AddMessage />}
          </div>
        </div>
        {SHOW_VIRTUAL_PET && <VirtualPet />}
        {SHOW_FOOTER && <Footer />}
      </div>
    </Router>
  );
}

export default App;
