import React from "react";
import "./BlogPage.css";
// import { StoryPage } from "../ui-components";
import BlogImage from "../assets/sample_images/IMG_8798.jpg";

const BlogPage = () => {
  return (
    <div className="BlogPage">
      {/* <StoryPage /> */}
      <div class="blog-main">
        <div class="blog-left">
          <div class="brand-name">
            <h1>Brand Name</h1>
            <h3>COMING 2023</h3>
          </div>
          <div class="blog-vision">
            <h3>Our Vision</h3>
            <p>
              Our vision at [Company Name] is to become the leading provider of
              premium quality clothing for men and women who value style,
              comfort, and durability. We are committed to using the finest
              materials and craftsmanship to create garments that not only look
              and feel great, but also stand the test of time.
            </p>
          </div>
        </div>
        <div class="blog-right">
          <img src={BlogImage} alt="bharat"/>
        </div>
      </div>

      {/* <h1>Our Vision</h1>
      <ul>
        <li>
          Our vision at [Company Name] is to become the leading provider of
          premium quality clothing for men and women who value style, comfort,
          and durability. We are committed to using the finest materials and
          craftsmanship to create garments that not only look and feel great,
          but also stand the test of time.
        </li>
        <br />
        <li>
          Our goal is to establish a strong brand identity that is synonymous
          with excellence and sophistication, and to build a loyal customer base
          that trusts and values our products. We strive to continuously
          innovate and improve our offerings, using the latest technology and
          design trends to create garments that meet the evolving needs and
          preferences of our customers.
        </li>
        <br />
        <li>
          As a company, we are committed to sustainability and ethical
          practices, and we take pride in sourcing materials and manufacturing
          our products in a responsible and transparent manner. We believe that
          fashion should not come at the expense of people or the planet, and we
          are dedicated to minimizing our environmental impact and supporting
          fair labor practices.
        </li>
        <br />
        <li>
          We envision a future where [Company Name] is a household name,
          recognized and trusted by customers around the world for the
          exceptional quality and style of our garments. We look forward to
          continuing to grow and evolve as a company, and to delivering on our
          promise of premium quality clothing that meets the needs and desires
          of our customers.
        </li>
      </ul> */}
    </div>
  );
};

export default BlogPage;
