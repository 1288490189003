import "./ExperimentsPage.css";
import React from "react";

function ExperimentsPage() {
  return (
    <div className="experiments">
      <h1>In Build</h1>
    </div>
  );
}

export default ExperimentsPage;
