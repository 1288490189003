import React from 'react'
import "./Promotion.css"
const Promotion = () => {
  return (
    <div>
      <div className='promotion-top row'>
        <div className='col-md-4 col-sm-6 col-10'>
            <div className='removeFooterMargin row'>
                <span className='largeLabel'>Stay Connected</span>
            </div>
            <div className='removeFooterMargin row'>
                <span className='labelContent'>Gain exclusive access to our campaigns, product launches, partnerships, and special promotions.</span>
            </div>
        </div>
        <div className='col-md-4 col-sm-6 col-10' >
          <div className='removeFooterMargin row'>
          <div className="removePadding removePaddingr col-sm-9 col-9">
            <input type="email" className="inputPromotion form-control1" id="exampleFormControlInput1" placeholder="Enter your email address"/>
            </div>
            <div className="removePadding tikIcon col-sm-2 col-3">
              <svg width="65" height="54" viewBox="0 0 65 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.5H59C62.0376 0.5 64.5 2.96243 64.5 6V48C64.5 51.0376 62.0376 53.5 59 53.5H0.5V0.5Z" fill="white"/>
                <g clipPath="url(#clip0_60_149)">
                <path d="M29.8334 34.2542L23.3501 27.7708L24.2334 26.8875L29.8334 32.4875L41.7668 20.5542L42.6501 21.4375L29.8334 34.2542Z" fill="#FF5E00"/>
                </g>
                <path d="M0.5 0.5H59C62.0376 0.5 64.5 2.96243 64.5 6V48C64.5 51.0376 62.0376 53.5 59 53.5H0.5V0.5Z" stroke="#FF5E00"/>
                <defs>
                  <clipPath id="clip0_60_149">
                    <rect width="20" height="20" fill="white" transform="translate(23 17.1875)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>

          </div>
          <div className='removeFooterMargin row'>
            <span className='labelContent1'>By signing up you are agreeing to receive marketing communications from us. For more information please see our Privacy Policy.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Promotion
