import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { addToFav } from "./favoritesSlice";

export const selectCart = (state) => state.cart;
export const selectCartTotalCount = createSelector(
  selectCart,
  (cart) => cart.reduce((totalCount, item) => totalCount + item.quantity, 0)
);

export const selectCartItems = createSelector(
  selectCart,
  (cart) => cart
);

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addToCart(state, action) {
      const existingItem = state.find((i) => i.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity++;
      } else {
        state.push({ ...action.payload, quantity: 1 });
      }

      if (existingItem && existingItem.quantity === 1) {
        // Dispatch the addToFav action
        action.dispatch(addToFav(existingItem));
      }
    },
    removeFromCart(state, action) {
      const itemId = action.payload;
      const index = state.findIndex((item) => item.id === itemId);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    updateQuantity(state, action) {
      const { id, quantity } = action.payload;
      const item = state.find((i) => i.id === id);
      if (item) {
        item.quantity = quantity;
      }
    },
    clearCart(state) {
      return [];
    },
  },
});

export const { addToCart, removeFromCart, updateQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
