import "./FavoritesPage.css";
import React from "react";
import FavCart from "./FavCart";
import { useSelector } from "react-redux";
import { selectFavorites } from "../redux/selectors";
// import { Box, Grid } from "@mui/material";
// import ProductItems from "../ui-components/ProductItems";
function FavoritesPage() {
     const favorites = useSelector(selectFavorites);
    console.log(favorites)
  return (
     <div className="favorites">
      <h2>Favorite Items</h2>
      {favorites.length === 0 ? (
        <p>No favorite items.</p>
      ) : (
        <div className="favRow row">
          {favorites.map((item) => (
                <FavCart className="col-sm-2 col-5"
                  clothingProducts={item}
                ></FavCart>
          ))}
        </div>
      )}
    </div>
  );
}

export default FavoritesPage;
