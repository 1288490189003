import { Link } from "react-router-dom";
import "./ProfilePage.css";

function Profile() {
  return (
    <div className="profile">
       <h1>My Profile</h1>

      <div className="rowTiles row">
        <Link to="/orders" className="profileTile col-md-3 col-sm-3 col-12">
          {/* <Link to="/orders"> */}
            <label className="profileLabel">Your Orders</label><br/>
            <span className="profileSpan">Track, return, cancel an order,
                download invoice or buy again</span>
          </Link>
        {/* </div> */}
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Login & Security</label><br/>
          <span className="profileSpan">Edit login, name, and mobile
              number</span>
        </Link>
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Experiments</label><br/>
          <span className="profileSpan">New developments and features</span>
        </Link>
      </div>
      <div className="rowTiles row">
        <Link to='/account' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Your Addresses</label><br/>
          <span className="profileSpan">Edit, remove or set default
              address</span>
        </Link>
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Your Payments</label><br/>
          <span className="profileSpan">View all transactions, manage payment methods and settings</span>
        </Link>
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Collabration</label><br/>
          <span className="profileSpan">Connect with us to start a collaboration on 
          next collection release</span>
        </Link>
      </div>
      <div className="rowTiles row">
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Customer Service</label><br/>
          <span className="profileSpan">Browse self service options, help
  articles or contact us</span>
        </Link>
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Digital Services</label><br/>
          <span className="profileSpan">View or respond to messages
  from Amazon, Sellers and Buyers</span>
        </Link>
        <Link to='' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">NFT</label><br/>
          <span className="profileSpan">Connect your Web3 Wallet</span>
        </Link>
      </div>
        <div className="rowTiles row">
        <Link to='/shop' className="profileTile col-md-3 col-sm-3 col-12">
          <label className="profileLabel">Collection</label><br/>
          <span className="profileSpan">View our past and upcoming collections</span>
        </Link>
      </div>
    </div>
  );
}

export default Profile;
