import React, {useState, useEffect} from 'react';
import './Cartitems.css'
import { useDispatch } from 'react-redux';
import { updateQuantity } from '../redux/cartSlice';
import { removeFromCart } from '../redux/cartSlice';
function CartItem(props){    
    console.log(props.clothingProducts,'cart Items')
    const itemList = props.clothingProducts;
    const dispatch = useDispatch();
    const [quantity, setquantity] = useState(itemList.quantity)
    const [totalPrice, setTotalPrice] = useState(itemList.quantity * itemList.Price)

    const removeItem = () => {
        dispatch(removeFromCart(itemList.id))
        console.log(itemList.id,'remove')
    }
    useEffect(() => {
        setTotalPrice(itemList.Price * quantity)
        dispatch(updateQuantity({id:itemList.id, quantity}))
    },[quantity, itemList.Price,itemList.id,dispatch])
    const changeHandle = (e) => {
        console.log(e)
        if(e === '+'){
            setquantity(quantity+1)
        }
        else {
            if(quantity > 1){
                setquantity(quantity - 1)
            }
            else{
                setquantity(1)
            }
        }
    }
    
    return (
        <div>
            <div className="row divs removeFooterMargin" >
                <div className='col-sm-2 col-4'>
                    <img alt="productImage" style={{width:'120px'}} src={itemList.Image}/>
                </div>
                <div className=" col-sm-3 col-4">
                    <div className='removeFooterMargin row'>
                        {itemList.Description}
                    </div>
                    <div className='itemProp'>
                        <div className='removeFooterMargin row'>
                            Color : {itemList.Color}
                        </div>
                        <div className='removeFooterMargin row'>
                            Size : {itemList.Size}
                        </div>
                    </div>
                </div>
                <div className='col-sm-1 col-3 removeButton'>
                    <div className=''>
                    <button type="button" className="btn btn-light" onClick={() => removeItem()}>Remove</button>
                    </div>
                </div>
                <div className='col-sm-3 col-4 cart1'>
                    <span>Qty:</span>
                    <button type="button" className="btn btn1 btn-light rounded-circle" onClick={() => changeHandle('-')}>-</button>
                    <span>{quantity}</span>
                    <button type="button" className="btn btn1 btn-light rounded-circle" onClick={() => changeHandle('+')}>+</button>
                    
                </div>
                <div className='price1 col-sm-1 col-2'>
                    <span>$ {totalPrice}</span>
                </div>
            </div>
        </div>
    )
}

export default CartItem;