import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import { withReduxStateSync } from "redux-state-sync";
import favoritesReducer from "./favoritesSlice"; // Import the favorites reducer

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
);

// Add the favorites reducer to the rootReducer
const rootReducerWithFavorites = combineReducers({
  ...rootReducer,
  favorites: favoritesReducer,
});

const stateSyncConfig = {
  blacklist: ["_persist"],
};

const syncedPersistedReducer = withReduxStateSync(
  persistedReducer,
  stateSyncConfig
);

const store = configureStore({
  reducer: syncedPersistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
