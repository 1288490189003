import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import { Model } from './Model'
import CanvasLoader from "./Loader";
import "./ModelCanvas.css";


export default function ModelCanvas() {
  const ref = useRef()
  return (
    <div className='model-canvas'>
      <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
        <Suspense fallback={<CanvasLoader />}>
          <OrbitControls
            ref={ref}
            enableZoom={false}
            maxPolarAngle={Math.PI / 2}
            minPolarAngle={Math.PI / 2}
            autoRotate
          />
          <Stage controls={ref} preset="rembrandt" intensity={1} environment={null}>
            false
            <Model />
            false
          </Stage>
        </Suspense>
      </Canvas>
    </div>
  )
}
